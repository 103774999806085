import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import TextContainer from "../components/text-container";
import BlockContent from "../components/block-content";

export const query = graphql`
query PrivacyPageQuery {
  page: sanityPrivacyPolicy(_id: {eq: "privacyPolicy"}) {
    _rawBody
    title
    seo {
      title
      description
    }
  }
}

  `;

const privacyPage = props => {
  const { page } = props.data;
  return (
    <Layout title={page.seo?.title || page.title} description={page.seo?.description || ""} location="/privacy">
      <TextContainer className="pb-16">
        <h1 className="text-center pt-4">{page.title}</h1>
        <BlockContent blocks={page._rawBody} />
      </TextContainer>
    </Layout>
  )
}

export default privacyPage